import React from "react"
import Header from "../components/header.js"
import Helmet from "react-helmet"

const FourOhFourPage = () => (
  <>
    <Helmet>
      <meta http-equiv="refresh" content="5; URL=/" />
    </Helmet>
    <div className="single bg-offWhite">
      <div className="lg:flex">
        <Header />
        <div className="entry-content w-full pt-6 pb-16 lg:py-12">
          <div className="lg:my-12 px-6 lg:px-12 max-w-6xl mx-auto">
            <p style={{ textAlign: "center", fontSize: "8em", lineHeight: 1 }}>
              🤭
            </p>
            <h1 style={{ textAlign: "center" }}>404</h1>
            <p style={{ textAlign: "center" }}>
              Sorry! This page couldn't be found. You'll be redirected
              automatically.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default FourOhFourPage
